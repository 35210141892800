import { createBrowserRouter, createRoutesFromElements, RouterProvider ,Route, Outlet } from 'react-router-dom';
import Program from './pages/program/Program';
import Header from "./pages/header/Header";
import Come from './pages/come/Come'
import Contact from './pages/contact/Contact'
import Rooms from './pages/rooms/Rooms'
import Home from './pages/home/Home';
import Participants from './pages/participants/Participants';

function App() {

  const router = createBrowserRouter (
    createRoutesFromElements (
      <Route path='/' element={<Root />}>
        <Route index element={<Home />}/>
        <Route path='/program' element={<Program />} />
        <Route path='/rooms' element={<Rooms />} />
        <Route path='/come' element={<Come />} />
        <Route path='/participants' element={<Participants />} />
        <Route path='/contact' element={<Contact />} />
      </Route>
    )
  )

  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

const Root = () => {

  return (
    <div className="container-fluid">
      <Header />
      <Outlet />
    </div>
  )
}

export default App;