import React, { useState } from 'react'
import {GiChurch, GiPhotoCamera, GiSandwich, GiLinkedRings, GiFemaleLegs, GiCakeSlice} from "react-icons/gi"
import {TfiCar} from "react-icons/tfi"
import {IoRestaurantOutline} from "react-icons/io5"
import {WiStars} from "react-icons/wi"
import {HiOutlineSpeakerphone} from "react-icons/hi"
import {AiOutlineInfoCircle} from "react-icons/ai"
import {RiComputerFill} from "react-icons/ri"

function Program() {

  const title = ["Egyházi szertartás", "Fotózás", "Konvoj Indulása", "Állófogadás", "Polgári szertartás", "Kiscsoportos fotózás", " Vacsora", "Nyitótánc", "Torta", "Csillagszórós bevonulás", "Éjféli vacsora", "Program"]
  const [current, setCurrent] = useState(11)
  const img = ["church", "photo", "menet", "kaja", "polgari", "photo", "kaja", "tanc", "cake", "spark", "kaja", "cover"]

  const onClick = (e) => {
    setCurrent(e.target.id)
  }


  return (
    <section className="program container-fluid ">
      <div className="container-fluid text">
        <div className="main-text">
          <p>Kedves utasaink! A menetrend pontos betartása érdekében a Nagy nap során:</p>
          <ul>
            <li>Rendszeressen látogassanak el az aradine.hu oldalra<span className='icon'> <RiComputerFill/></span></li>
            <li>Hallgassák a "hangosbemondót" <span className='icon'> <HiOutlineSpeakerphone/></span></li>
            <li>Kövessék kihelyezett tájékoztatóinkat<span className='icon'> <AiOutlineInfoCircle/></span></li>
          </ul>
          <p>Figyelmüket és megértésüket köszönjük!</p>
        </div>
      </div>
      <div className="container-fluid main">
        <div className="list">
          <ul>
            <li  id='0' className={current === "0" ? "active" : ""} onClick={onClick}> <GiChurch /> 14:00</li>
            <li  id='1' className={current === "1" ? "active" : ""} onClick={onClick}><GiPhotoCamera />14:30</li>
            <li  id='2' className={current === "2" ? "active" : ""} onClick={onClick}><TfiCar/>15:00</li>
            <li  id='3' className={current === "3" ? "active" : ""} onClick={onClick}><GiSandwich/>15:30</li>
            <li  id='4' className={current === "4" ? "active" : ""} onClick={onClick}>< GiLinkedRings />17:00</li>
            <li  id='5' className={current === "5" ? "active" : ""} onClick={onClick}><GiPhotoCamera />18:00</li>
            <li  id='6' className={current === "6" ? "active" : ""} onClick={onClick}><IoRestaurantOutline/>19:00</li>
            <li  id='7' className={current === "7" ? "active" : ""} onClick={onClick}><GiFemaleLegs/>21:00</li>
            <li  id='8' className={current === "8" ? "active" : ""} onClick={onClick}><GiCakeSlice/>23:00</li>
            <li  id='9' className={current === "9" ? "active" : ""} onClick={onClick}><WiStars/>00:00</li>
            <li  id='10' className={current === "10" ? "active" : ""} onClick={onClick}><IoRestaurantOutline/>00:30</li>
          </ul>
        </div>
        <div className={" current " +img[current]}>
            <h2>{title[current]}</h2>
        </div>
      </div>

      <div className="footer-as">
        <div className="footer-text">
            <p>Valahányszor elfog a bánat a világ állása miatt, a Heathrow repülőtér utasvárójára gondolok. Kezd elterjedni az az általános vélekedés, hogy a gyűlölet és a kapzsiság világában élünk. De én nem így látom. Nekem úgy tűnik, a szeretet mindenütt jelen van. Előfordul, hogy nem különösebben méltóságteljes vagy említésre méltó, de mindig ott van: apák és fiúk, anyák és lányok, férjek és feleségek, barátnők és barátok, régi cimborák. Mikor a gépek becsapódtak az ikertornyokba, tudtommal a halál előtt álló emberek telefonhívásainak egyike sem a gyűlöletről vagy bosszúról szólt. Mind a szeretet üzenetei voltak.</p>
            <p className='love'>Igazából szerelem...</p>
         </div>
      </div>
    </section>
  )
}

export default Program