import React, { useState } from "react";
import {useNavigate} from "react-router-dom"

function Come() {

  const navigate = useNavigate()

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [name5, setName5] = useState("");
  const [food, setFood] = useState("");
  const [cbox1, setCbox1] = useState(false);
  const [cbox2, setCbox2] = useState(false);
  const [cbox3, setCbox3] = useState(false);
  const [cbox4, setCbox4] = useState(false);
  const [cbox5, setCbox5] = useState(false);
  const [cbox6, setCbox6] = useState(false);
  const [cbox7, setCbox7] = useState(false);
  const [cbox8, setCbox8] = useState(false);
  const [cbox9, setCbox9] = useState(false);
  const [cbox10, setCbox10] = useState(false);
  const [plus, setPlus] = useState("");
  const [show, setShow] = useState(false);

  const onClick = () => {
    setName1(name1);
    setName2(name2);
    setName3(name3);
    setName4(name4);
    setName5(name5);
    setFood(food);
    setCbox1(cbox1);
    setCbox2(cbox2);
    setCbox3(cbox3);
    setCbox4(cbox4);
    setCbox5(cbox5);
    setCbox6(cbox6);
    setCbox7(cbox7);
    setCbox8(cbox8);
    setCbox9(cbox9);
    setCbox10(cbox10);
    setPlus(plus);

    if (name1 !== "") {
      setShow(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    fetch("api/message/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name1: name1,
        name2: name2,
        name3: name3,
        name4: name4,
        name5: name5,
        food: food,
        cbox1: cbox1,
        cbox2: cbox2,
        cbox3: cbox3,
        cbox4: cbox4,
        cbox5: cbox5,
        cbox6: cbox6,
        cbox7: cbox7,
        cbox8: cbox8,
        cbox9: cbox9,
        cbox10: cbox10,
        plus: plus,
      }),
    });

    alert("Köszönjük!")
    navigate("/participants")

  };

  if (show) {
    return (
      <div className="row container-fluid come justify-content-center">
        <div className={show ? "popup" : "hide"}>
          <h2>Kérlek ellenőrizd az adatokat!</h2>
          <div className="text-gr">
            <h5>1. {name1 !== "" ? name1 : "-"}</h5>
            <h5>2. {name2 !== "" ? name2 : "-"}</h5>
            <h5>3. {name3 !== "" ? name3 : "-"}</h5>
            <h5>4. {name4 !== "" ? name4 : "-"}</h5>
            <h5>5. {name5 !== "" ? name5 : "-"}</h5>
            <p>Ételallergiák: {food}</p>
          </div>

          <div className="btn-gr">
            <button className="btn fs-5 btn-success" onClick={onSubmit}>
              Minden rendben
            </button>
            <button
              className="btn fs-5 btn-danger"
              onClick={() => setShow(false)}
            >
              Módosítok
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row container-fluid come justify-content-center">
        <form
          className="form col-lg-6 col-md-8 col-sd-9 p-5"
          onSubmit={onSubmit}
        >
          <h5>Hányan jöttök?</h5>
          <label htmlFor="name1">Név</label>
          <input
            required
            id="name1"
            type="text"
            name="name"
            value={name1}
            className="form-control input"
            placeholder="1. Név..."
            onChange={(e) => setName1(e.target.value)}
          />
          <label htmlFor="name2">Név</label>
          <input
            id="name2"
            type="text"
            name="name"
            className="form-control input"
            placeholder="2. Név..."
            value={name2}
            onChange={(e) => setName2(e.target.value)}
          />
          <label htmlFor="name3">Név</label>
          <input
            id="name3"
            type="text"
            name="name"
            className="form-control input"
            placeholder="3. Név..."
            value={name3}
            onChange={(e) => setName3(e.target.value)}
          />
          <label htmlFor="name4">Név</label>
          <input
            id="name4"
            type="text"
            name="name"
            className="form-control input"
            placeholder="4. Név..."
            value={name4}
            onChange={(e) => setName4(e.target.value)}
          />
          <label htmlFor="name5">Név</label>
          <input
            id="name5"
            type="text"
            name="name"
            className="form-control input"
            placeholder="5. Név..."
            value={name5}
            onChange={(e) => setName5(e.target.value)}
          />

          <label htmlFor="erzeknység">
            Bármi más amit tudnunk kell?
            <small className="fs-6">
             ( Vegetáriánus vagy? Vagy van valamilyen ételallergiád? Fontos, hogy
              tudjuk! Írd le nekünk!)
            </small>
          </label>
          <textarea
            onChange={(e) => setFood(e.target.value)}
            value={food}
            name="erzekenyseg"
            id="erzekenyseg"
            cols="30"
            rows="5"
          ></textarea>
          <div className="cbox-title">
            <h5>Legyen tánc!</h5>
            <small>Milyen zenékre szeretnétek táncolni a lagzin?</small>
          </div>
          <div className="form-check">
            <input
              value={cbox1}
              onChange={(e) => setCbox1(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type2"
            />
            <label className="form-check-label" htmlFor="type2">
              Magyar Retro zenék a 80-as 90-es évekből
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox2}
              onChange={(e) => setCbox2(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type3"
            />
            <label className="form-check-label" htmlFor="type3">
              Külföldi Retro zenék a 80-as 90-es évekből
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox3}
              onChange={(e) => setCbox3(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type4"
            />
            <label className="form-check-label" htmlFor="type4">
              Disco korszak a 70-es 80-as évekből
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox4}
              onChange={(e) => setCbox4(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type5"
            />
            <label className="form-check-label" htmlFor="type5">
              Latin slágerek a 80-as évektől máig
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox5}
              onChange={(e) => setCbox5(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type6"
            />
            <label className="form-check-label" htmlFor="type6">
              Magyar alternatív és fesztivál slágerek
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox6}
              onChange={(e) => setCbox6(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type7"
            />
            <label className="form-check-label" htmlFor="type7">
              Rock klasszikusok a 80-as évektől
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox7}
              onChange={(e) => setCbox7(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type8"
            />
            <label className="form-check-label" htmlFor="type8">
              Funky, Hip Hop és R'n'B zenék napjainkig
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox8}
              onChange={(e) => setCbox8(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type9"
            />
            <label className="form-check-label" htmlFor="type9">
              House, Elektronikus tánczenék
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox9}
              onChange={(e) => setCbox9(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type10"
            />
            <label className="form-check-label" htmlFor="type10">
              2000-es évek és a mai nagy slágerek
            </label>
          </div>
          <div className="form-check">
            <input
              value={cbox10}
              onChange={(e) => setCbox10(e.target.checked)}
              className="form-check-input"
              type="checkbox"
              id="type"
            />
            <label className="form-check-label" htmlFor="type">
              Rock 'n Roll örökzöld klasszikusok
            </label>
          </div>
          <label htmlFor="type11">Ha valami lemaradt volna a listáról:</label>
          <input
            value={plus}
            onChange={(e) => setPlus(e.target.value)}
            className="form-control"
            type="text"
            id="type11"
          />

          <button onClick={onClick} className="btn btn-outline-dark mt-3">
            Elküld
          </button>
        </form>
      </div>
    );
  }
}

export default Come;
