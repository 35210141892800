import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FiAlignJustify } from "react-icons/fi";
import Timer from './Timer';

function Header() {

  const [width, setWidth] = useState(null)
  const [click, setClick] = useState(false)
  const [small, setSmall] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(null)
  const [active, setActive] = useState(5)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return() => {
      window.removeEventListener("resize", handleResize)
    }
  }, [setWidth])

  const onClick = () => {
    setClick(!click)
  }

  const handleScroll = () => {
    if(typeof window !== 'undefined') {
      if(window.scrollY > 200) {
        setSmall(true)
      } else {
        setSmall(false)
      }
      setLastScrollY(window.scrollY)
    }
  }  

    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll);
  
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }
    }, [lastScrollY]);

  if(width > 991) {
    return (
      <header>
        <nav>
          <div className="container-fluid">
          <a href="/">
              <div className="top container-fluid">
                <div className='wedding-title'>
                  <h1 className='display-1'>Eszter</h1>
                  <h1 className='display-1'>Áron</h1>
                </div>
                <Timer />
              </div>
            </a>
              <div className={small ? "bottom container-fluid fixed-top bg-light" : "bottom container-fluid"}>
                <ul>
                  <li><Link to="/program" onClick={(e) => setActive(e.target.id)} className={active === "0" ? "active" : "" } id='0'>Program</Link></li>
                  <li><Link to="/rooms" onClick={(e) => setActive(e.target.id)} className={active === "1" ? "active" : "" } id='1'>Szállás</Link></li>
                  <li><Link to="/come" onClick={(e) => setActive(e.target.id)} className={active === "2" ? "active" : "" } id='2'>Jelezz vissza!</Link></li>
                  <li><Link to="/participants" onClick={(e) => setActive(e.target.id)} className={active === "3" ? "active" : "" } id='3'>Résztvevők</Link></li>
                  <li><Link to="/contact" onClick={(e) => setActive(e.target.id)} className={active === "4" ? "active" : "" } id='4'>Kapcsolat</Link></li>
                </ul>
              </div>
          </div>
        </nav>
      </header>
    )
  } else {
    return (
      <header>
        <nav>
          <div className="container-fluid rounded-3">
            <a href="/">
              <div className="top container-fluid">
              <h1 className='display-3 hamburger-title'>Eszter & Áron</h1>
              <Timer />
              </div>
            </a>
              <div className="hamburger container-fluid">
                <div className={click ? "close" : "hide"} onClick={onClick}></div>
                <FiAlignJustify onClick={onClick}/>
                <ul  onClick={onClick} className={click ? "hamburger open" : "hamburger"}>
                  <li onClick={onClick}><Link to="/program">Program</Link></li>
                  <li onClick={onClick}><Link to="/rooms">Szállás</Link></li>
                  <li onClick={onClick}><Link to="/come">Eljössz?</Link></li>
                  <li><Link to="/participants">Résztvevők</Link></li>
                  <li onClick={onClick}><Link to="/contact">Kapcsolat</Link></li>
                </ul>
              </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header