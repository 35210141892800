import React, { useEffect, useState } from 'react'
import img1 from "./img/bg.jpg"
import img2 from "./img/bg2.jpg"
import {useNavigate} from 'react-router-dom'

function Rooms() {

  const [rooms, setRooms] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const getRooms = async() => {
      const resp = await fetch('/api/rooms')
      const data = await resp.json()
      setRooms(data)
    }
    getRooms()
  }, [])

  return (
    <div className="container-fluid room">
      <div className="text">
        <div className="main-text">
          <h3>Szállás</h3>
          <p>Tudjuk hogy sokan messziről érkeztek majd esküvőnkre és nem mindenki szeret a csillagos ég alatt aludni. Ha ti is így vagytok ezzel, akkor válasszatok az alábbi szálláslehetőségek közül.  </p>
        </div>
      </div>
      <div className="container opt">
          <h4>Pihenés Billegpusztán</h4>
        <div className="img">
          <img src={img1} alt="" />
        </div>
        <div className="imgText">
          <p>Egy késő estig tartó rendezvény után nincs is jobb, mint pár lépést megtenni a kényelmes ágyig, hogy kipihenjük a nap izgalmait. A Balogh–Esterházy-kastély korhű szobái a birtok "csendes", zöld parkjára néző ablakaikkal kényelmes pihenést biztosítanak minden kedves vendégünknek</p>
          <p>Sajnos a kastély csak limitált számban tud szállást biztosítani (40 fő). 14 000 Ft/fő reggelivel együtt.</p>
          <p>Görgess lejjebb és ha van olyan szoba ami megfelel, keress minket telefonon.</p>
            <button onClick={() => navigate("/contact")}>Kapcsolat</button>
            <a target='blank' href="https://billegikastely.hu/szallas/">A kastély honlapja</a>
            </div>
          </div>

        <div className="free-rooms">
          <h2>Szabad szobák a kastélyban:</h2>
          {rooms.map((item, id) => {
            return (
            <div className="room-res"  key={id} style={{backgroundColor: item.reserved ? "#A44343" : "white"}}>
              <p>{id + 1 +". "}{item.description}</p>
              <p className='important'>{item.reserved ? "foglalt" : "szabad"}</p>
            </div>)
          })}
      </div>
      <div className="opt reverse container g-0">
        <div className="imgText top">
        <h4>Sirály Panzió</h4>
          <p>Mindössze negyven percnyi autóútra a nyüzsgő fővárosi zajtól és forgatagtól, a festői Öreg tó partján várja vendégeit Tatán a Sirály Panzió.</p>
          <p>A közvetlenül a parti sétány mellett épült, s nemrég felújított 13 szobás szálláshely, egész évben várja azokat, akik egy romantikus kisvárosban, annak a természetvédelmi területén lévő tó partján, hajnali madárcsicsergésre ébredve szeretnének egy, vagy több napot megszállni.</p>
          <a target='blank' href="http://www.siralypanziotopart.hu/">Sirály Panzió</a>
        </div>
        <div className="imgText top">
          <h4>Egyéb szállások</h4>
          <a target='blank' href="http://www.tataapartmanok.hu/apartmanok/">Tata apartmanok</a>
          <a target='blank' href="https://www.szallastata.hu/apartmanok/kiado-apartmanok-tatan">Szállás Tata</a>
        </div>

        <div className="img">
          <img src={img2} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Rooms