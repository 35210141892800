import React, { useEffect, useState } from 'react'
import { GiPhone } from 'react-icons/gi'
import { TfiEmail, TfiMapAlt } from 'react-icons/tfi'
import { CgWebsite } from 'react-icons/cg'

function Contact() {

  const [width, setWidth] = useState(null)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return() => {
      window.removeEventListener("resize", handleResize)
    }
  }, [setWidth])

  return (
    <div className='container-fluid contact'>
        <div className="bg">
        <div className="row justify-content-center">
          <div className="card1">
            <div className="header">
              <h5>Agárdi Eszter</h5>
              <p>Menyasszony</p>
            </div>
            <div className="body">
              <a href="tel: 06202664255">{width > 640 ? "06202664255" : <GiPhone/>}</a>
              <a href="mailto: eszter.agardi@gmail.com">{width > 640 ? "eszter.agardi@gmail.com" : < TfiEmail /> }</a>
            </div>
          </div>

          <div className="card1">
            <div className="header">
              <h5>Aradi Áron</h5>
              <p>Vőlegény</p>
            </div>
           <div className="body">
              <a href="tel: 06308601077">{width > 640 ? "06308601077" : <GiPhone/>}</a>
              <a href="mailto: aradiaron89@gmail.com">{width > 640 ? "aradiaron89@gmail.com" : < TfiEmail />}</a>
           </div>
          </div>

          <div className="card1 ">
            <div className="header">
              <h5>Szabados-Mavrák Kata</h5>
              <p>Ceremóniamester</p>
            </div>
           <div className="body">
            <a href="tel: 06202664255">{width > 640 ? "06202664255" : <GiPhone/>}</a>
            <a href="mailto: kata@noiceremoniamester.hu">{width > 640 ? "kata@noiceremoniamester.hu" : < TfiEmail />}</a>
           </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="card2">
            <h5>Balogh-Esterházy-kastély</h5>
            <div className="adiv">
              <a target="blank" href="https://www.google.com/maps/place/Nasz%C3%A1ly,+Balogh%E2%80%93Esterh%C3%A1zy-kast%C3%A9ly/@47.6935693,18.239208,17z/data=!3m1!4b1!4m6!3m5!1s0x476a4ef4d0556031:0xccdb2e9271d1f925!8m2!3d47.6935693!4d18.239208!16s%2Fg%2F12z65ws5t"><TfiMapAlt /></a>
              <a target="blank" href="https://billegikastely.hu/"><CgWebsite/></a>
            </div>
          </div>
          <div className="card2">
            <h5>Tatai Szent Kereszt felmagasztalása katolikus templom</h5>
            <a target="blank" href="https://www.google.com/maps/place/Tatai+Szent+Kereszt+felmagasztal%C3%A1sa+katolikus+templom/@47.6429821,18.312361,17z/data=!4m14!1m7!3m6!1s0x476a47f7366559b1:0x5b5164b9014f6ef9!2sTatai+Szent+Kereszt+felmagasztal%C3%A1sa+katolikus+templom!8m2!3d47.6429821!4d18.3145497!16s%2Fg%2F11f6z4ct13!3m5!1s0x476a47f7366559b1:0x5b5164b9014f6ef9!8m2!3d47.6429821!4d18.3145497!16s%2Fg%2F11f6z4ct13"><TfiMapAlt /></a>
          </div>
      </div>
        </div>
    </div>
  )
}

export default Contact