import React, { useEffect, useState } from 'react'

function Participants() {

    const [names, setNames] = useState([])
    const [music, setMusic] = useState([])

    useEffect(() => {
        async function fetchData() {
            const response = await fetch('api/message') 
            const data = await response.json()
            setNames(data)
        }
        fetchData()
    }, [])

    useEffect(() => {
        function getMusic () {
            let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            for(let i = 0; i < names.length; i++) {
                if(names[i].cbox1) {
                    arr[0] += 1
                }
                if(names[i].cbox2) {
                    arr[1] += 1
                }
                if(names[i].cbox3) {
                    arr[2] += 1
                }
                if(names[i].cbox4) {
                    arr[3] += 1
                }
                if(names[i].cbox5) {
                    arr[4] += 1
                }
                if(names[i].cbox6) {
                    arr[5] += 1
                }
                if(names[i].cbox7) {
                    arr[6] += 1
                }
                if(names[i].cbox8) {
                    arr[7] += 1
                }
                if(names[i].cbox9) {
                    arr[8] += 1
                }
                if(names[i].cbox10) {
                    arr[9] += 1
                }
            }
            return arr
        }

        setMusic(getMusic())
    }, [names])

    

  return (
    <div className='container-fluid participants grid-container'>
        <div className="wrapper grid-item">
            <h2>Ők már jelezték hogy ott lesznek:</h2>
            {names.map((name, index) => 
                <ul className='names' key={index}>
                    <li>{name.name1}</li>
                    <li>{name.name2}</li>
                    <li>{name.name3}</li>
                    <li>{name.name4}</li>
                    <li>{name.name5}</li>
                </ul>
            )}
        </div>
        <div className="music grid-item">
                <ul className='musics'>
                    <h2>Legyen tánc! <small className='fs-5'>(szavazás állása)</small></h2>
                    <li><span>Magyar Retro zenék a 80-as 90-es évekből</span> <span className='score'>{music[0]}</span></li>
                    <li><span>Külföldi Retro zenék a 80-as 90-es évekből</span> <span className='score'>{music[1]}</span></li>
                    <li> <span>Disco korszak a 70-es 80-as évekből</span> <span className='score'>{music[2]}</span></li>
                    <li><span>Latin slágerek a 80-as évektől máig</span> <span className='score'>{music[3]}</span></li>
                    <li> <span>Magyar alternatív és fesztivál slágerek</span> <span className='score'>{music[4]}</span></li>
                    <li><span>Rock klasszikusok a 80-as évektől</span> <span className='score'>{music[5]}</span></li>
                    <li><span>Funky, Hip Hop és R'n'B zenék napjainkig</span> <span className='score'>{music[6]}</span></li>
                    <li><span>House, Elektronikus tánczenék</span> <span className='score'>{music[7]}</span></li>
                    <li><span>2000-es évek és a mai nagy slágerek</span> <span className='score'>{music[8]}</span></li>
                    <li><span>Rock 'n Roll örökzöld klasszikusok</span> <span className='score'>{music[9]}</span></li>
                </ul>
        </div>
    </div>
  )
}

export default Participants