import React from 'react'
import  kep1  from './img/1.jpg'
import  kep2  from './img/2.jpg'
import { useNavigate } from "react-router-dom";

function Home () {

  const navigate = useNavigate()

  return (
    <section className="home container-fluid">
        <div className="container">
          <div className="images ">
            <img src={kep1} alt="" />
            <div className="text">
              <h4 className='py-3 display-6'>Kedves Meghívottak!</h4>
                <p>A király legkisebb leányának Eszternek kezét Áron lovag nyerte el. Kézfogójukra a násznép messze földről hivatalos. János atya már gyakorolja az imákat a szolganép pedig feni a késeket. Az ökörsütéshez a kertben sátrakat állíttatnak, és akár száz hordót is csapra vernek a jeles napon. Ezúton invitálunk hetedíziglen, hogy szem- és fültanúi legyetek annak, hogy az úr 2023. évének július havának 29-ik napján  a házasság szent kötelékét választva örök hűséget fogadunk egymásnak. Azt követően kezdődhet a hét napig tartó mulatság! 
                </p>
                <div className="button-group">
                  <button onClick={() => navigate("/come")} className='btn btn-outline-dark'>Eljössz?</button>
                  <button onClick={() => navigate("/rooms")} className='btn btn-outline-dark'>Szállás</button>
                </div>
          </div>
            <img src={kep2} alt="" />
          </div>
        </div>
    </section>
  )
}

export default Home
